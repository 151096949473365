import ApolloConfigProvider from 'framework/base/utils/ApolloConfigProvider';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { AuthorizationProvider } from 'spec';
import App from './App';
import './App.css';
import store from './redux/store';
import reportWebVitals from './reportWebVitals';

ReactDOM.createRoot(document.getElementById('root')).render(
  <BrowserRouter>
    <Provider store={store}>
      <AuthorizationProvider>
        <ApolloConfigProvider>
          <App />
        </ApolloConfigProvider>
      </AuthorizationProvider>
    </Provider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
