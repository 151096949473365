// Themes
const THEMES = {
  DEFAULT: 'DEFAULT',
  DESIGNER: 'DESIGNER',
  DARK: 'DARK',
  LIGHT: 'LIGHT',
  BLUE: 'BLUE',
  GREEN: 'GREEN',
  INDIGO: 'INDIGO'
};

export default THEMES;
