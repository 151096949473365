import componentSizesDesktop from './componentSizesDesktop';
import componentSizesLaptop from './componentSizesLaptop';
import componentSizesTablet from './componentSizesTablet';
import componentSizesMobile from './componentSizesMobile';

const componentSizes = (layoutType) => {
  switch (layoutType) {
    case 'desktop': {
      return componentSizesDesktop;
    }
    case 'laptop': {
      return componentSizesLaptop;
    }
    case 'tablet': {
      return componentSizesTablet;
    }
    case 'mobile': {
      return componentSizesMobile;
    }
    default:
      return null;
  }
};

export default componentSizes;
