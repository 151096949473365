import createCache from '@emotion/cache';
import {
  CacheProvider,
  ThemeProvider as StyledThemeProvider
} from '@emotion/react';
import { ThemeProvider } from '@mui/material/styles';
import { StyledEngineProvider } from '@mui/styled-engine-sc';
import { AryAlertTypes } from 'framework/base/components/AryAlert';
import { GET_DESCRIPTION_TEMPLATES } from 'framework/base/constants/aryGraphqlSchemas';
import DescriptionTemplatesContext from 'framework/base/contexts/DescriptionTemplatesContext';
import LayoutTypeContext from 'framework/base/contexts/LayoutTypeContext';
import ShowCurrenciesContextProvider from 'framework/base/contexts/ShowCurrenciesContextProvider';
import useAryAlert from 'framework/base/hooks/useAryAlert';
import { useAryLazyQuery } from 'framework/base/hooks/useAryGraphql';
import useAryLayoutType from 'framework/base/hooks/useAryLayoutType';
import useTemplateParsing from 'framework/base/hooks/useTemplateParsing';
import AryLoadingAnimation from 'framework/desktop/AryLoadingAnimation';
import React, { Suspense, lazy, useEffect, useRef, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useStore } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import {
  setDoBootstrap,
  setErrors,
  setNoVersionError
} from 'redux/slices/systemSlice';
import {
  ApiAuthorizationConstants,
  AuthRouteGuard as AuthGuard,
  LoginCallbackHandler,
  authService,
  useAuth
} from 'spec';
import { prefixer } from 'stylis';
import rtlPlugin from 'stylis-plugin-rtl';
import './assets/fontYekanBakh.css';
import createTheme from './framework/base/theme/index';
import useTheme from './framework/base/theme/useTheme';
import './language/translation';
import appRoutes from './routes';

const AryNoVersionModal = lazy(() =>
  import('framework/base/components/AryNoVersionModal')
);

const { ApplicationPaths } = ApiAuthorizationConstants;

const cacheRtl = createCache({
  key: 'muirtl',
  prepend: true,
  stylisPlugins: [prefixer, rtlPlugin]
});

const DirectionProvider = ({ rtl = false, children }) => {
  if (!rtl) {
    return children;
  }
  return <CacheProvider value={cacheRtl}>{children}</CacheProvider>;
};

function lazyRoutes(routes, layoutType) {
  if (!routes || routes.length === 0) return null;
  return (
    <>
      {routes.map((route) => (
        <Route
          key={route.path}
          path={route.path}
          element={
            <AuthGuard
              anonymousAccess={route.anonymousAccess}
              authKeys={route.authKeys}
              path={route.path}
              component={
                layoutType === 'mobile' && route.mobile
                  ? route.mobile
                  : route.component
              }
            />
          }
        >
          {lazyRoutes(route.routes, layoutType)}
        </Route>
      ))}
    </>
  );
}

const DEFAULT_LANGUAGE = 'fa';

const App = () => {
  const { t, i18n } = useTranslation();
  const reduxStore = useStore();
  const { theme } = useTheme();
  const langDirection = i18n.dir();
  const layoutType = useAryLayoutType();
  const auth = useAuth();
  document.body.dir = langDirection;

  const hasVersion = useRef(false);
  const [noVersionModalOpen, setNoVersionModalOpen] = useState(false);
  const [isVersionChecked, setIsVersionChecked] = useState(false);
  const [AryAlert, showAlert] = useAryAlert({
    onClose: () => {
      reduxStore.dispatch(setErrors([]));
    }
  });

  const [getDescriptionTemplates, { data: descriptionTemplates }] =
    useAryLazyQuery({
      gqlConfig: GET_DESCRIPTION_TEMPLATES,
      items: `
    id
    body
    `
    });

  const parseDescription = useTemplateParsing(descriptionTemplates);
  const appTheme = createTheme(theme, undefined, langDirection, layoutType);

  async function bootstrap() {
    await getDescriptionTemplates(DEFAULT_LANGUAGE);
  }

  useEffect(() => {
    (async () => {
      hasVersion.current = await authService.isVersionValid();
      if (hasVersion.current !== null) {
        setIsVersionChecked(true);
      }
    })();
  });

  useEffect(() => {
    reduxStore.subscribe(() => {
      const { noVersionError } = reduxStore.getState().systemReducer;
      if (noVersionError) {
        setNoVersionModalOpen(true);
        reduxStore.dispatch(setNoVersionError(false));
      }
    });
  }, []);

  reduxStore.subscribe(() => {
    const { errors } = reduxStore.getState().systemReducer;
    if (errors.length) {
      showAlert(
        AryAlertTypes.ERROR,
        parseDescription(errors[0].templateId, errors[0].metadata)
      );
    }
  });

  useEffect(() => {
    if (window.location.pathname !== ApplicationPaths.LoginCallback) {
      bootstrap();
    }

    reduxStore.subscribe(() => {
      const { noVersionError, doBootstrap } =
        reduxStore.getState().systemReducer;
      if (noVersionError) {
        setNoVersionModalOpen(true);
        reduxStore.dispatch(setNoVersionError(false));
      }
      if (doBootstrap) {
        reduxStore.dispatch(setDoBootstrap(false));
        bootstrap();
      }
    });
  }, []);

  return (
    <HelmetProvider>
      <Helmet
        titleTemplate={`%s | ${t('Ariyana')}`}
        defaultTitle={`${t('Ariyana')} - ${t('Support And Software Development Panel')}`}
      />
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={appTheme}>
          <StyledThemeProvider theme={appTheme}>
            <DirectionProvider rtl={langDirection === 'rtl'}>
              <LayoutTypeContext.Provider value={layoutType}>
                <DescriptionTemplatesContext.Provider
                  value={descriptionTemplates}
                >
                  <ShowCurrenciesContextProvider>
                    <Routes>
                      {auth && isVersionChecked ? (
                        <>
                          <Route
                            path="/"
                            element={
                              <Navigate replace to="/dashboard/my-tickets" />
                            }
                          />
                          {lazyRoutes(appRoutes, layoutType)}
                        </>
                      ) : (
                        <Route path="/" element={<AryLoadingAnimation />} />
                      )}
                      <Route
                        exact
                        path={ApplicationPaths.LoginCallback}
                        element={<LoginCallbackHandler />}
                      />
                      <Route path="/*" element={<AryLoadingAnimation />} />
                    </Routes>
                    {AryAlert}
                    {noVersionModalOpen && (
                      <Suspense fallback="">
                        <AryNoVersionModal
                          open={noVersionModalOpen}
                          handleClose={() => setNoVersionModalOpen(false)}
                          showConnectedBranchesButton
                        />
                      </Suspense>
                    )}
                  </ShowCurrenciesContextProvider>
                </DescriptionTemplatesContext.Provider>
              </LayoutTypeContext.Provider>
            </DirectionProvider>
          </StyledThemeProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </HelmetProvider>
  );
};

export default App;
