import { componentSizesExtraVars } from 'spec';

const componentSizesDesktop = {
  navbar: {
    height: '80px'
  },
  sidebar: {
    opened: {
      width: '270px'
    },
    closed: {
      width: '70px'
    }
  },
  fileUploader: '435px',
  adornmentIcon: '20px',
  inputBoxHeight: '48px',
  filterFieldHeight: '40px',
  showMoreRowsOfTableButtonHeight: '32px',
  ...componentSizesExtraVars.common,
  ...componentSizesExtraVars.desktop
};

export default componentSizesDesktop;
