const components = (theme, layoutType) => ({
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true
    }
  },
  MuiLink: {
    defaultProps: {
      underline: 'hover'
    }
  },
  MuiCardHeader: {
    defaultProps: {
      titleTypographyProps: {
        variant: 'h5Bold'
      }
    },
    styleOverrides: {
      action: {
        marginTop: '-4px',
        marginRight: '-4px'
      }
    }
  },
  MuiCardContent: {
    styleOverrides: {
      root: {
        '&:last-child': {
          paddingBottom: layoutType === 'mobile' ? '14px' : '24px'
        }
      }
    }
  },
  MuiCard: {
    styleOverrides: {
      root: {
        borderRadius: layoutType === 'mobile' ? '4px' : '6px',
        boxShadow:
          'rgba(50, 50, 93, 0.025) 0px 2px 5px -1px, rgba(0, 0, 0, 0.05) 0px 1px 3px -1px',
        backgroundImage: 'none'
      }
    }
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        backgroundImage: 'none'
      }
    }
  },
  MuiChip: {
    styleOverrides: {
      root: {
        borderRadius: '6px',
        fontSize: '13px'
      }
    }
  },
  MuiStep: {
    styleOverrides: {
      root: {
        paddingLeft: layoutType === 'mobile' && '4px',
        paddingRight: layoutType === 'mobile' && '4px'
      }
    }
  },
  MuiStepLabel: {
    styleOverrides: {
      label: {
        fontSize:
          layoutType === 'laptop' || layoutType === 'tablet' ? '14px' : '16px',
        color: theme.stepper.label.color,
        '&.Mui-active': {
          color: theme.text.primary.color
        },
        '&.Mui-completed': {
          color: theme.text.primary.color
        }
      },
      iconContainer: {
        padding: layoutType === 'mobile' && 0
      }
    }
  },
  MuiStepButton: {
    styleOverrides: {
      root: {
        fontSize:
          layoutType === 'laptop' || layoutType === 'tablet' ? '14px' : '16px'
      }
    }
  },
  MuiStepIcon: {
    styleOverrides: {
      root: {
        color: theme.stepper.background.color
      }
    }
  },
  MuiTabs: {
    styleOverrides: {
      root: {
        minHeight: layoutType === 'mobile' && '42px',
        height: layoutType === 'mobile' && '42px',
        boxShadow: layoutType === 'mobile' && '0px 4px 4px rgba(0, 0, 0, 0.05)',
        backgroundColor:
          layoutType === 'mobile' && theme.tab.mobile.backgroundColor,
        borderTop:
          layoutType === 'mobile' && `1px solid ${theme.tab.border.color}`
      },
      indicator: {
        height: '3px'
      }
    }
  },
  MuiTab: {
    styleOverrides: {
      root: {
        color: theme.text.secondary.color,
        minHeight: layoutType === 'mobile' && '38px',
        height: layoutType === 'mobile' && '38px',
        fontSize:
          layoutType === 'desktop'
            ? '20px'
            : layoutType === 'laptop'
            ? '18px'
            : layoutType === 'tablet'
            ? '16px'
            : '14px',
        fontWeight: layoutType === 'mobile' && '500',
        '&.Mui-selected': {
          color: theme.text.primary.color,
          fontWeight: layoutType === 'mobile' && '900'
        }
      }
    }
  },
  MuiMenu: {
    styleOverrides: {
      paper: {
        marginTop:
          layoutType === 'laptop' || layoutType === 'tablet' ? '0' : '5px',
        boxShadow:
          '0px 4px 14px 2px rgba(0, 0, 0, 0.12), 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 10px 1px rgba(0, 0, 0, 0.14)'
      }
    }
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        minWidth: '76px',
        minHeight: layoutType === 'desktop' ? '38px' : '30px',
        height: layoutType === 'desktop' ? '38px' : '30px',
        fontSize: layoutType === 'desktop' ? '14px' : '12px',
        '&.Mui-selected': {
          backgroundColor: theme.select.selectedOption.backgroundColor
        },
        '&:hover': {
          backgroundColor: theme.select.hoveredOption.backgroundColor
        }
      }
    }
  },
  MuiButton: {
    variants: [
      {
        props: { sidepadding: 'none' },
        style: {
          paddingRight: '0',
          paddingLeft: '0'
        }
      },
      {
        props: { vertpadding: 'none' },
        style: {
          paddingTop: '0',
          paddingBottom: '0'
        }
      },
      {
        props: { minwidth: 'none' },
        style: {
          minWidth: '0'
        }
      },
      {
        props: { minwidth: 'small' },
        style: {
          minWidth: layoutType === 'desktop' ? '86px' : '80px'
        }
      },
      {
        props: { category: 'mobileSubmit' },
        style: {
          width: '100%',
          position: 'relative',
          bottom: '0',
          borderRadius: '0'
        }
      },
      {
        props: { category: 'filter' },
        style: {
          height: '40px'
        }
      }
    ],
    styleOverrides: {
      outlined: {
        backgroundColor: 'white'
      },
      sizeSmall: {
        minWidth: layoutType === 'mobile' ? '63px' : '100px',
        padding: layoutType === 'mobile' ? '6px 16px' : '8px 16px',
        fontSize: '12px',
        fontWeight: '900',
        lineHeight: '16px'
      },
      sizeMedium: {
        minWidth:
          layoutType === 'laptop' || layoutType === 'tablet'
            ? '110px'
            : '130px',
        padding:
          layoutType === 'laptop' || layoutType === 'tablet'
            ? '9px 12px'
            : '12px 18px',
        fontSize:
          layoutType === 'laptop' || layoutType === 'tablet' ? '12px' : '14px',
        fontWeight: '900',
        lineHeight: '16px'
      },
      sizeLarge: {
        minWidth: '176px',
        padding:
          layoutType === 'laptop' || layoutType === 'tablet'
            ? '8px 24px'
            : '16px 24px',
        fontSize:
          layoutType === 'desktop'
            ? '18px'
            : layoutType === 'laptop' || layoutType === 'tablet'
            ? '14px'
            : '16px',
        fontWeight: '900',
        lineHeight: '24px'
      },
      iconSizeMedium: {
        '& :nth-of-type(1)': { fontSize: '16px' }
      }
    }
  },
  MuiInputLabel: {
    styleOverrides: {
      sizeSmall: {
        lineHeight: '26px'
      },
      root: {
        marginTop:
          (layoutType === 'laptop' || layoutType === 'tablet') && '-3px'
      },
      shrink: {
        marginTop: 0
      },
      outlined: {
        color: theme.textField.label.color
      },
      formControl: {
        fontSize: layoutType === 'desktop' ? '14px' : '12px',
        fontWeight: 500,
        lineHeight: layoutType === 'desktop' ? '16px' : '14px'
      }
    }
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        height:
          layoutType === 'desktop'
            ? '48px'
            : layoutType === 'laptop'
            ? '36px'
            : layoutType === 'tablet'
            ? '36px'
            : '44px',
        backgroundColor: theme.input.white.color,
        '&.Mui-disabled': {
          background: '#F2F2F2',
          borderRadius: '4px'
        },
        '&.MuiInputBase-sizeSmall': {
          height: '40px'
        },
        '&.MuiInputBase-readOnly': {
          backgroundColor: theme.palette.disable.main,
          borderRadius: '4px'
        }
      },
      input: {
        height:
          layoutType === 'desktop'
            ? '48px'
            : layoutType === 'laptop' || layoutType === 'tablet'
            ? '36px'
            : '44px',
        padding: '0 14px',
        backgroundColor: theme.input.white.color,
        '&.Mui-disabled': {
          background: '#F2F2F2',
          borderRadius: '4px'
        },
        '&.MuiInputBase-readOnly': {
          backgroundColor: theme.palette.disable.main,
          borderRadius: '4px',
          height:
            layoutType === 'desktop'
              ? '48px'
              : layoutType === 'laptop' || layoutType === 'tablet'
              ? '36px'
              : '44px'
        },
        '&.MuiInputBase-inputSizeSmall': {
          height: '40px'
        }
      },
      multiline: {
        height: 'auto'
      }
    }
  },
  MuiOutlinedInput: {
    styleOverrides: {
      sizeSmall: {
        height: '40px'
      },
      root: {
        background: theme.input.white.color,
        height:
          layoutType === 'desktop'
            ? '48px'
            : layoutType === 'laptop'
            ? '36px'
            : layoutType === 'tablet'
            ? '36px'
            : '44px',
        '&.Mui-disabled': {
          backgroundColor: '#F2F2F2',
          color: 'rgba(0, 0, 0, 0.54)',
          borderRadius: '4px'
        },
        '&.Mui-disabled.Mui-error': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.error.main
          }
        }
      },
      notchedOutline: {
        borderColor: theme.textField.border.color
      },
      multiline: {
        height: 'auto'
      },
      input: {
        fontSize:
          layoutType === 'desktop'
            ? '14px'
            : layoutType === 'laptop'
            ? '12px'
            : layoutType === 'tablet'
            ? '12px'
            : '12px',
        color: theme.text.primary.color,
        height:
          layoutType === 'desktop'
            ? '48px'
            : layoutType === 'laptop' || layoutType === 'tablet'
            ? '36px'
            : '44px',
        padding: '0 14px',
        '&::placeholder': {
          opacity: 1,
          color: theme.textField.label.color
        }
      }
    }
  },
  MuiInputAdornment: {
    styleOverrides: {
      root: {
        '& p': {
          fontSize:
            layoutType === 'laptop' || layoutType === 'tablet' ? '10px' : '12px'
        }
      }
    }
  },
  MuiTypography: {
    styleOverrides: {
      root: {
        color: theme.text.primary.color
      },
      tooltip: {
        color: theme.tooltip.label.color
      }
    }
  },
  MuiRadio: {
    styleOverrides: {
      root: {
        color: theme.radio.color
      }
    }
  },
  MuiCheckbox: {
    styleOverrides: {
      root: {
        color: theme.checkbox.color,
        '& svg': {
          fontSize:
            layoutType === 'laptop' || layoutType === 'tablet'
              ? '20px'
              : '24px',
          height:
            layoutType === 'laptop' || layoutType === 'tablet' ? '20px' : '24px'
        },
        '&.Mui-focusVisible svg': {
          color: '#0077B6',
          backgroundColor: '#EBF4F9',
          borderRadius: '2px'
        }
      }
    }
  },
  MuiSelect: {
    styleOverrides: {
      select: {
        height: '100%',
        display: 'flex',
        alignItems: 'center'
      },
      icon: {
        color: theme.select.icon.color
      }
    }
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        color: theme.text.primary.color,
        padding:
          layoutType === 'laptop' || layoutType === 'tablet' ? '10px' : '16px'
      }
    }
  },
  MuiFormControlLabel: {
    styleOverrides: {
      label: {
        userSelect: 'none',
        fontSize:
          layoutType === 'desktop'
            ? '14px'
            : layoutType === 'laptop'
            ? '12px'
            : layoutType === 'tablet'
            ? '12px'
            : '12px'
      }
    }
  },
  MuiListItemText: {
    styleOverrides: {
      primary: {
        fontSize:
          layoutType === 'desktop'
            ? '14px'
            : layoutType === 'laptop'
            ? '12px'
            : layoutType === 'tablet'
            ? '12px'
            : '12px'
      }
    }
  }
});

export default components;
