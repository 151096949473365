import { lazy } from 'react';

const routes = [
  {
    path: '/dashboard',
    component: lazy(() => import('./Dashboard')),
    anonymousAccess: true,
    routes: [
      {
        path: 'errors/error-401',
        component: lazy(() => import('./pages/desktop/errors/Error401')),
        mobile: lazy(() => import('./pages/mobile/errors/Error401'))
      },
      {
        path: 'my-tickets',
        component: lazy(() => import('./pages/desktop/my-tickets/MyTickets')),
        mobile: lazy(() => import('./pages/mobile/my-tickets/MyTickets')),
        authKeys: ['Developer']
      },
      {
        path: 'ticket-details',
        component: lazy(() =>
          import('./pages/desktop/my-tickets/TicketDetails')
        ),
        mobile: lazy(() => import('./pages/mobile/my-tickets/TicketDetails'))
      }
    ]
  },
  {
    path: '/*',
    component: lazy(() => import('./pages/desktop/errors/Error404')),
    mobile: lazy(() => import('./pages/mobile/errors/Error404'))
  }
];

export default routes;
