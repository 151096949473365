import marginDesktop from './marginDesktop';
import marginLaptop from './marginLaptop';
import marginTablet from './marginTablet';
import marginMobile from './marginMobile';

const margin = (layoutType) => {
  switch (layoutType) {
    case 'desktop': {
      return marginDesktop;
    }
    case 'laptop': {
      return marginLaptop;
    }
    case 'tablet': {
      return marginTablet;
    }
    case 'mobile': {
      return marginMobile;
    }
    default:
      return null;
  }
};

export default margin;
