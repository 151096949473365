import React from 'react';
import styled from '@emotion/styled';

const Svg = styled.svg``;

const Rect = styled.rect`
  height: 100%;
  width: 100%;
  fill: transparent;
`;

const Container = styled.div`
  width: ${(props) => props.size || 250}px;
  height: ${(props) => props.size || 250}px;
  position: absolute;
  top: calc(50% - ${(props) => (props.size ? props.size / 2 : 125)}px);
  left: calc(50% - ${(props) => (props.size ? props.size / 2 : 125)}px);
`;

const CircleLeft = styled.path`
  animation: CircleLeftAnimate 1s ease-in-out infinite;
  @keyframes CircleLeftAnimate {
    0% {
      fill: #91A4BC;
    }
    10% {
      fill: #91A4BC;
    }
    20% {
      fill: #0077B6;
    }
    30% {
      fill: #0077B6;
    }
    40% {
      fill: #0077B6;
    }
    50% {
      fill: #0077B6;
    }
    60% {
      fill: #91A4BC;
    }
    80% {
      fill: #91A4BC;
    }
    90% {
      fill: #91A4BC;
    }
`;

const MiddleCircle = styled.path`
  animation: MiddleCircleAnimate 1s ease-in-out infinite;
  @keyframes MiddleCircleAnimate {
    0% {
      fill: #b2c2d6;
    }
    10% {
      fill: #b2c2d6;
    }
    20% {
      fill: #b2c2d6;
    }
    30% {
      fill: #00b4d8;
    }
    40% {
      fill: #00b4d8;
    }
    50% {
      fill: #00b4d8;
    }
    60% {
      fill: #00b4d8;
    }
    70% {
      fill: #00b4d8;
    }
    80% {
      fill: #b2c2d6;
    }
    90% {
      fill: #b2c2d6;
    }
  }
`;
const RightWing = styled.path`
  animation: RightWingAnimate 1s ease-in-out infinite;
  @keyframes RightWingAnimate {
    0% {
      fill: #91a4bc;
    }
    10% {
      fill: #91a4bc;
    }
    20% {
      fill: #91a4bc;
    }
    30% {
      fill: #91a4bc;
    }
    40% {
      fill: #0077b6;
    }
    50% {
      fill: #0077b6;
    }
    60% {
      fill: #0077b6;
    }
    70% {
      fill: #0077b6;
    }
    80% {
      fill: #0077b6;
    }
    90% {
      fill: #0077b6;
    }
  }
`;

const LeftWing1 = styled.path`
  animation: LeftWing1Animate 1s ease-in-out infinite;
  @keyframes LeftWing1Animate {
    0% {
      fill: #b2c2d6;
    }
    10% {
      fill: #b2c2d6;
    }
    20% {
      fill: #b2c2d6;
    }
    30% {
      fill: #b2c2d6;
    }
    40% {
      fill: #b2c2d6;
    }
    50% {
      fill: #0096c7;
    }
    60% {
      fill: #0096c7;
    }
    70% {
      fill: #0096c7;
    }
    80% {
      fill: #0096c7;
    }
    90% {
      fill: #b2c2d6;
    }
  }
`;
const LeftWing2 = styled.path`
  animation: LeftWing2Animate 1s ease-in-out infinite;
  @keyframes LeftWing2Animate {
    0% {
      fill: #d1dbe8;
    }
    10% {
      fill: #d1dbe8;
    }
    30% {
      fill: #d1dbe8;
    }
    30% {
      fill: #d1dbe8;
    }
    40% {
      fill: #d1dbe8;
    }
    50% {
      fill: #d1dbe8;
    }
    60% {
      fill: #00b4d8;
    }
    70% {
      fill: #00b4d8;
    }
    80% {
      fill: #d1dbe8;
    }
    90% {
      fill: #d1dbe8;
    }
  }
`;

const AryLoadingAnimation = ({ size }) => (
  <div>
    <Container size={size}>
      <Svg viewBox="0 0 880 560" fill="none" xmlns="http://www.w3.org/2000/svg">
        <Rect width="880" height="560" fill="white" />
        <RightWing
          d="M637.918 417.571C576.424 317.92 514.931 218.278 453.446 118.627C444.516 104.156 423.315 104.549 414.928 119.342C409.66 128.642 404.384 137.943 399.116 147.244C395.095 154.348 395.265 163.073 399.565 170.014C455.629 260.502 511.694 350.99 567.759 441.479C571.889 448.147 579.226 452.168 587.082 452.065C597.779 451.918 608.475 451.78 619.172 451.634C636.542 451.406 647.034 432.34 637.918 417.571Z"
          fill="#91A4BC"
        />
        <LeftWing1
          d="M500.941 218.92L372.237 440.847C368.047 448.07 360.281 452.505 351.877 452.469L262.318 452.106C244.387 452.034 233.214 432.802 242.148 417.389L416.186 117.111C425.241 101.491 448.026 101.642 456.867 117.378L501.092 196.092C505.09 203.204 505.028 211.87 500.941 218.92Z"
          fill="#B2C2D6"
        />
        <LeftWing2
          d="M463.693 156.803C319.829 409.834 313.464 419.849 300.716 439.862C299.845 441.226 297.318 445.172 292.55 448.254C286.117 452.414 279.667 452.525 277.141 452.438C272.184 452.377 267.234 452.324 262.277 452.263C244.377 452.19 233.224 432.95 242.142 417.53L415.875 117.116C424.914 101.489 447.658 101.64 456.484 117.383C456.674 117.72 458.105 119.698 459.743 122.263C461.191 124.551 462.906 127.237 464.311 130.113C464.673 130.855 467.095 135.847 467.809 140.043C469.11 147.728 465.013 154.48 463.693 156.803Z"
          fill="#D1DBE8"
        />
        <CircleLeft
          d="M381.628 260.044C395.23 261.583 407.504 251.803 409.043 238.201C410.581 224.598 400.802 212.324 387.199 210.786C373.597 209.247 361.323 219.027 359.785 232.629C358.246 246.231 368.026 258.505 381.628 260.044Z"
          fill="#91A4BC"
        />
        <MiddleCircle
          d="M460.97 409.849C474.572 411.387 486.846 401.608 488.385 388.005C489.923 374.403 480.144 362.129 466.541 360.59C452.939 359.052 440.665 368.832 439.126 382.434C437.588 396.036 447.367 408.31 460.97 409.849Z"
          fill="#B2C2D6"
        />
      </Svg>
    </Container>
  </div>
);

export default AryLoadingAnimation;
